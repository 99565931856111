














































































































































import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import moment from "moment";
import { Ban, BanActionPayload } from "./store/types";
import { BAN_NAMESPACE } from "./store";
import { Broadcast, BroadcastGetters } from "../Broadcast/broadcast/types";
import { HOUR_IN_SECONDS } from "@/helpers/consts";
import { RouteName } from "@/router/types";
import ConfirmDialog from "../ConfirmDialog.vue";
import ButtonMenuItemList from "../ButtonMenuItemList.vue";
import { BroadcastPayload } from "@/types";
import { UsersActions, UsersGetters } from "@/store/users/types";
import { User } from "@/spect8-core-vue/src/types";

@Component({
  components: {
    ButtonMenuItemList,
    ConfirmDialog,
  },
})
export default class Bans extends Vue {
  @Getter(`${BAN_NAMESPACE}/bans`)
  readonly allBans!: Bans[];

  @Action(`${BAN_NAMESPACE}/getBans`)
  getAllBans!: () => Promise<Ban[]>;

  @Action(`${BAN_NAMESPACE}/deleteBan`)
  deleteBanRequest!: (id: string) => Promise<void>;

  @Action(`${BAN_NAMESPACE}/addBan`)
  addBan!: (ban: BanActionPayload) => Promise<Ban>;

  @Action(UsersActions.GetUsers)
  getUsers!: () => Promise<void>;

  @Getter(UsersGetters.Users)
  readonly users!: User[];

  @Getter(BroadcastGetters.Broadcasts)
  readonly broadcasts!: Broadcast[];

  RouteName = RouteName;

  softban = true;
  isAddActive = false;
  search = "";
  dialog = false;
  iDItemToDelete = "";
  nameItemToDelete = "";
  component = "Bans";
  username = "";
  //TODO: User v UserInfo ??
  selectedUser: User | null = null;
  inputValue = "";

  banDurationHours = 1;

  get durations() {
    return [
      {
        text: this.$tc("timeUnit.hours", 1, { value: 1 }),
        value: 1,
      },
      {
        text: this.$tc("timeUnit.hours", 12, { value: 12 }),
        value: 12,
      },
      {
        text: this.$tc("timeUnit.hours", 24, { value: 24 }),
        value: 24,
      },
      {
        text: this.$tc("timeUnit.days", 30, { value: 30 }),
        value: 720,
      },
      {
        text: this.$t("Indefinitely"),
        value: 900000,
      },
    ];
  }

  get headers() {
    return [
      {
        text: this.$t("Username"),
        value: "userInfo.displayName",
      },
      {
        text: this.$t("User ID"),
        value: "userInfo.id",
      },
      {
        text: this.$t("bans.broadcastLabel"),
        value: "broadcasts",
        filterable: false,
        sortable: false,
      },
      {
        text: this.$t("bans.tableHeaders.banDate"),
        value: "timeBanned",
        filterable: false,
      },
      {
        text: this.$t("bans.tableHeaders.banDuration"),
        value: "bannedUntil",
        filterable: false,
      },
      {
        text: this.$t("dataTable.actions"),
        value: "actions",
        filterable: false,
        sortable: false,
        align: "center",
      },
    ];
  }

  selectedBroadcasts: Broadcast[] = [];

  created() {
    this.getAllBans();
    this.getUsers();
  }

  banDate(timestamp: string) {
    return this.$d(new Date(timestamp), "DateTimeShort");
  }

  banDuration(banTimestamp: string) {
    const dateOne = moment();
    const dateTwo = moment(banTimestamp);
    const duration = moment.duration(dateTwo.diff(dateOne));
    const days = duration.asDays();
    const hours = duration.asHours();
    const minutes = duration.asMinutes();
    const seconds = duration.asSeconds();
    if (days <= 1 && hours > 1) {
      return this.$tc("bans.hoursLeft", Math.round(hours), {
        value: Math.round(hours),
      });
    } else if (days >= 7500) {
      return "Indefinitely";
    } else if (minutes <= 60) {
      return this.$tc("bans.minutesLeft", Math.round(minutes), {
        value: Math.round(minutes),
      });
    } else if (seconds <= 60) {
      return this.$tc("bans.secondsLeft", Math.round(seconds), {
        value: Math.round(seconds),
      });
    } else {
      return this.$tc("bans.daysLeft", Math.round(days), {
        value: Math.round(days),
      });
    }
  }

  get disabled() {
    return !(this.selectedUser && this.selectedBroadcasts.length);
  }

  get usersLength() {
    return this.allBans.length;
  }

  addActive() {
    this.isAddActive = true;
  }

  banUser() {
    if (!this.selectedUser) return;

    this.addBan({
      banDurationSeconds: this.banDurationHours * HOUR_IN_SECONDS,
      userId: this.selectedUser.id,
      messageRouter: {
        tenantWide: false,
        broadcastIds: this.selectedBroadcasts.map((b: Broadcast) => {
          return b.id;
        }),
        channelIds: [],
      },
      soft: this.softban,
    });

    this.selectedUser = null;
  }

  close() {
    this.$emit("close");
  }

  async deleteBan(bannedUserId: string) {
    this.dialog = false;
    await this.deleteBanRequest(bannedUserId);
  }

  showDeletedItem(item: string, name: string) {
    this.iDItemToDelete = item;
    this.nameItemToDelete = name;
    this.dialog = !this.dialog;
  }

  getBroadcastNames(broadcasts: BroadcastPayload[]): string[] {
    return broadcasts.map((broadcast) => broadcast.broadcastName);
  }
}
